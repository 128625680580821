<script>
import { copy } from '../../../lib/objects';
import Vue from 'vue';
import draggable from 'vuedraggable';
import MetricModal from './MetricModal.vue';

/** @typedef {(import('typings/models').StatisticsMetrics) & { children?: StatisticsMetrics[] }} StatisticsMetrics */

/** @param {StatisticsMetrics[]} metrics */
const getNestedMetrics = (metrics) => {
  /** @type {Record<number, StatisticsMetrics[]>} */
  const childrenMap = {};
  /** @type {Record<number, StatisticsMetrics>} */
  const parentMap = {};
  for (const m of metrics) {
    if (!m.parent_id) {
      parentMap[m.id] = m;
      continue;
    }
    if (!childrenMap[m.parent_id]) {
      childrenMap[m.parent_id] = [];
    }
    childrenMap[m.parent_id].push(m);
  }

  /** @param a {StatisticsMetrics} @param b {StatisticsMetrics} */
  function byDisplayIndex(a, b) { return a.display_index - b.display_index }

  const nested = Object.values(parentMap).map((m) => {
    const children = childrenMap[m.id];
    if (!children) return m;
    return {
      ...m,
      children: childrenMap[m.id].sort(byDisplayIndex),
      expanded: false,
    };
  });
  return nested.sort(byDisplayIndex);
};

/** @param {StatisticsMetrics[]} metrics */
const flatChildren = (metrics) => {
  const flat = [];
  for (const m of metrics) {
    if (m.children) {
      flat.push(...m.children);
      delete m.children;
    }
    flat.push(m);
  }
  return flat;
};

export default {
  name: 'ReportsSettings',
  components: {
    draggable,
  },
  async beforeRouteEnter(to, from, next) {
    const metrics = await Vue.ovData.reportMetrics.getAll();
    next(async (vm) => {
      vm.metrics = getNestedMetrics(metrics);
      return vm;
    });
  },
  data() {
    return {
      /** Loading indicator */
      isBusy: false,
      /** Metrics editing indicator */
      isEditing: false,
      /** @type {(StatisticsMetrics)[]} */
      metrics: [],
    };
  },
  methods: {
    async loadData() {
      this.isBusy = true;
      try {
        const metrics = await Vue.ovData.reportMetrics.getAll();
        this.metrics = getNestedMetrics(metrics);
      } catch (e) {
        this.$ovNotify.error('Failed to load metrics');
        console.error(e);
      }
      this.isBusy = false;
    },

    async saveMetric() {
      const json = localStorage.getItem('reportMetric');
      if (!json) {
        return;
      }
      /** @type {StatisticsMetrics} */
      const metric = JSON.parse(json);
      if (metric.display_index === undefined) {
        const lastDisplayIndex = this.metrics.reduce((acc, m) => Math.max(acc, m.display_index), 0);
        metric.display_index = lastDisplayIndex + 1;
      }
      this.isBusy = true;
      try {
        await this.$ovData.reportMetrics.save(metric);
        this.$ovNotify.success('Metric has been saved');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error('Failed to save metric');
        console.error(e);
      }
      localStorage.removeItem('reportMetric');
      this.isBusy = false;
    },

    async saveMetricsOrder() {
      const indexesData = this.metrics.map((m) => ({ id: m.id, display_index: m.display_index }));
      this.isBusy = true;
      try {
        await this.$ovData.reportMetrics.updateOrder(indexesData);
        this.$ovNotify.success('Metrics order has been saved');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error('Failed to save metrics order');
        console.error(e);
      }
      this.isBusy = false;
    },

    /** @param {StatisticsMetrics} metric */
    async deleteMetric(metric) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this metric?',
      );
      if (!confirmed) {
        return;
      }
      this.isBusy = true;
      try {
        await this.$ovData.reportMetrics.delete(metric.id);
        this.$ovNotify.success('Metric has been deleted');
        this.loadData();
      } catch (e) {
        this.$ovNotify.error('Failed to delete metric');
        console.error(e);
      }
      this.isBusy = false;
    },

    /** @param {{ moved: {
     * element: StatisticsMetrics,
     * newIndex: number,
     * oldIndex: number }
     * }} event */
    onSort(event) {
      const { oldIndex, newIndex } = event.moved;
      const newMetrics = copy(this.metrics);
      newMetrics.splice(newIndex, 0, newMetrics.splice(oldIndex, 1)[0]);
      for (let index = 0; index < newMetrics.length; index++) {
        newMetrics[index].display_index = index;
      }
      this.metrics = newMetrics;
    },

    /** @param {StatisticsMetrics} m */
    hasChildren(m) {
      return m.children !== undefined && m.children.length > 0;
    },

    /** @param {StatisticsMetrics} [metric] */
    openMetricModal(metric) {
      const flattened = flatChildren(this.metrics)
      const metricsForCalc = getNestedMetrics(flattened);
      this.$modal.show(
        MetricModal,
        {
          metric,
          metricsForCalc
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': () => {
            this.saveMetric();
          },
        },
      );
    },
  },
};
</script>



<template lang="pug">
div
  loading(:active.sync="isBusy", :is-full-page="true")
  .widget
    .widget-header
      h1.title Metrics
      button.btn.btn-secondary.ml-2(
        type="button",
        @click="isEditing = !isEditing",
        v-b-tooltip.hover.bottom,
        :title="isEditing ? 'Exit Edit Mode' : 'Edit Order'"
      )
        i.la(:class="{'la-unlock': !isEditing,'la-lock': isEditing}")
      button.btn.btn-success.ml-2(
        type="button",
        @click="() => openMetricModal()",
        v-b-tooltip.hover.bottom,
        title="Add Metric"
      )
        i.la.la-plus
      button.btn.btn-primary.ml-2(
        type="button",
        @click="saveMetricsOrder",
        v-b-tooltip.hover.bottom,
        title="Save Metrics Order"
      )
        i.la.la-save

    .widget-body
      draggable(
        tag="div",
        handle="#drag-handle",
        :value="metrics",
        @change="onSort",
      )
        div(v-for="m in metrics", v-if="!m.hidden")
          .d-flex.gap-2.align-items-center
            span.metric-name#drag-handle(v-if="isEditing")
              i.la.la-grip-horizontal
            a.btn.btn-outline(v-if="hasChildren(m)")
              i.la(
                @click="m.expanded = !m.expanded",
                :class="{'la-angle-right': !m.expanded, 'la-angle-down': m.expanded}"
              )
            span.metric-name(:class="{'no-children': !hasChildren(m)}") {{ m.label }}
            span.metric-name(v-if=" m.short_label") ({{ m.short_label }})
            span.metric-info.text-muted(v-if="m.info")
              i.la.la-info-circle.ml-4.mr-1
              | {{ m.info }}
            span.actions.d-flex.gap-2(v-if="!m.reserved && !isEditing")
              a.btn.btn-action-default(
                v-b-tooltip.hover.bottom, 
                title="Edit",
                @click="() => openMetricModal(m)"
              )
                i.la.la-pencil
              a.btn.btn-action-danger(
                v-b-tooltip.hover.bottom,
                title="Delete"
                @click="() => deleteMetric(m)"
              )
                i.la.la-trash
          .children(v-if="m.expanded")
            div(v-for="c in m.children")
              span.metric-name  {{ c.label }}
              span.metric-info.text-muted(v-if="c.info")
                i.la.la-info-circle.ml-4.mr-1
                | {{ c.info }}
</template>

<style lang="scss" scoped>
#drag-handle {
  cursor: grab !important;
}

.no-children {
  margin-left: 1.9rem;
}

.metric-name {
  font-size: 1.4rem;
}

.actions a {
  font-size: 1.3rem;
  padding: 0;
}

.actions a:hover {
  color: #ffb047;
}

.children {
  margin-left: 5rem;
}
</style>