<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'MetricModal',
  components: {
    Treeselect
  },
  props: {
    metric: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    metricsForCalc: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      numerator: null,
      denominator: null,
    }
  },
  methods: {
    async save() {
      const name = this.metric.label.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
      const metric = {
        ...this.metric,
        name,
        calc: {
          numerator_id: this.numerator,
          denominator_id: this.denominator,
        },
      };
      localStorage.setItem('reportMetric', JSON.stringify(metric));
      this.$emit('close');
    },
  },
  mounted() {
    if (!this.metric.id) return;
    this.numerator = this.metric.calc_data.numerator_id;
    this.denominator = this.metric.calc_data.denominator_id;
  }
};
</script>



<template lang="pug">
div.default-modal
  .modal-header
    .modal-title {{metric.id ? 'Edit' : 'Add'}} Metric
    a.close(href="javascript:void(0);", @click="$emit('close')")
      i.la.la-times
  .modal-body
    .modal-body-inner
      form.form
        .form-group
          label Metric Label
          input.form-control(placeholder="Metric Label", v-model="metric.label")
        .form-group
          label Short Label
          input.form-control(placeholder="Short Label", v-model="metric.short_label")
        .form-group
          label Metric Info
          textarea.form-control(placeholder="Metric Info", v-model="metric.info")
        .form-group
          .row
            .col-6 
              label Formula Numerator
              treeselect(v-model="numerator", :multiple="false", :options="metricsForCalc")
            .col-6 
              label Formula Denominator
              treeselect(v-model="denominator", :multiple="false", :options="metricsForCalc")
          
        .form-group
          p Info: This ratio will be shown as percentage in the UI. E.g. 100 / 20 will be shown as 500.

        button.btn.btn-primary(type="button", @click="save()") Save
</template>
